<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>list-add</title>
    <path d="M6,2.5H19.5a1,1,0,0,0,0-2H6a1,1,0,0,0,0,2Z" />
    <path d="M6,8.5H19.5a1,1,0,0,0,0-2H6a1,1,0,0,0,0,2Z" />
    <path d="M10,13.5a1,1,0,0,0-1-1H6a1,1,0,1,0,0,2H9A1,1,0,0,0,10,13.5Z" />
    <path d="M2.5.5h-1a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Z" />
    <path d="M2.5,6.5h-1a1,1,0,0,0,0,2h1a1,1,0,1,0,0-2Z" />
    <path d="M2.5,12.5h-1a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Z" />
    <path
      d="M17.5,11A6.5,6.5,0,1,0,24,17.5,6.508,6.508,0,0,0,17.5,11Zm.75,9a.75.75,0,1,1-1.5,0V18.5a.25.25,0,0,0-.25-.25H15a.75.75,0,0,1,0-1.5h1.5a.25.25,0,0,0,.25-.25V15a.75.75,0,1,1,1.5,0v1.5a.25.25,0,0,0,.25.25H20a.75.75,0,0,1,0,1.5H18.5a.25.25,0,0,0-.25.25Z"
    />
  </svg>
</template>
